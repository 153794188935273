import React from 'react'
import styled from 'styled-components'
import {
  Tooltip,
  TextStyle
} from "@shopify/polaris"
const OfferStepWrapper = styled.div`
  width: 270px;
  .steps {
    margin-bottom: 3em;
    list-style: none;
    margin: 0;
    padding: 0;
    display: table;
    table-layout: fixed;
    width: 100%;
    color: #e6e6e6;
    height:4rem;
    .step {
      position: relative;
      display: table-cell;
      text-align: center;
      font-size: 0.875rem;
      color:#6D6875;
      
      &:before {
        content: attr(data-step);
        display: block;
        margin: 0 auto;
        background: #F0F1F2;
        border:1px solid #AAAAAA;
        color:#AAAAAA;
        width: 32px;
        height: 32px;
        text-align: center;
        margin-bottom: -4.2rem;
        line-height: 28px;
        border-radius: 100%;
        position: relative;
        z-index: 1;
        font-weight:700;
        font-size:1rem;
      }
      &:after {
        content: '';
        top: 16px;
        left: 50%;
        content: '';
        display: inline-block;
        width: 100%;
        position: absolute;
        bottom: 0;
        background-image: radial-gradient( ellipse, #000 2px, #000 3px, transparent 3px);
        background-size: 15px 5px;
        background-position: 0px 0;
        background-repeat: repeat-x;
      }
      &:last-child:after {
        display: none;
      }
      
      &.is-active {
        font-size:1.5rem;

        &:before {
          color: #15B68D;
          border:1px solid #15B68D;
          background: #F0F1F2;
          margin-bottom: -4.9rem;
        }
      }
      &.is-complete {
        &:before {
          content: "✓";
          color:  #15B68D;
          background:#F0F1F2;
          border:1px solid  #15B68D;
        }
        &:after {
          background-image: radial-gradient( ellipse, #15B68D 2px, #15B68D 3px, transparent 3px);
        }
      }
    }
  }
`
function CreateOfferSteps(props) {
  const { step } = props
  return (
          <OfferStepWrapper style={step === 1 ? {margin: '20px auto auto'}:{}}>
              <div className="steps">
                <li className={`step ${step === 1 ? "is-active" : step > 1 ? "is-complete":""}`} data-step="1" title="Choose upsell location">
                </li>
                <li className={`step ${step === 2 ? "is-active" : step > 2 ? "is-complete":""}`} data-step="2" title="Customize upsell">
                </li>
                <li className={`step ${step === 3 ? "is-active" : step > 3 ? "is-complete":""}`} data-step="3" title="Enable upsell in store">
                </li>
              </div>
          </OfferStepWrapper>
  )
}

export default CreateOfferSteps;